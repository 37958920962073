<template>
    <div id="page2body">
        <div class="section4">
            <div class="section4_title">
                <div class="section4_en">Business appreciation and analysis</div>
                <div class="section4_cn">业态赏析</div>
            </div>
            <div class="scroll_box">
                <div class="scroll_item" v-for="(item,index) in dataList" :key="index" @click="handleChange(index)">
                    <img :src="item.url" />
                    <div class="scroll_item_model">
                        <div class="scroll_item_model_text">
                            <div class="scroll_item_model_text_cn">{{item.cnText}}</div>
                            <div class="scroll_item_model_text_en">{{item.enText}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text_container" :class="isOpen ? 'active' : ''">
                <div class="text_title">
                    <div class="point"></div>
                    <div class="text_title_cn">{{textList[itemIndex].cnTitle}}</div>
                    <div class="text_title_en">{{textList[itemIndex].enTitle}}</div>
                </div>
                <div class="text">
                    {{ textList[itemIndex].text }}
                </div>
            </div>
        </div>
        <div class="section5">
            <div class="container1">
                <div class="container_title">
                    <div class="en_container">
                        <div class="point"></div>
                        LITERARY AND CREATIVE
                    </div>
                    <div class="cn_container">
                        特产文创店
                    </div>
                </div>
                <div class="img_container">
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/8.png" />
                </div>
            </div>
            <div class="container2">
                <div class="container_title">
                    <div class="en_container">
                        <div class="point"></div>
                        SNACK MELON AND FRUIT
                    </div>
                    <div class="cn_container">
                        零食瓜果铺
                    </div>
                </div>
                <div class="img_container">
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/9.png" />
                </div>
            </div>
            <div class="container1">
                <div class="container_title">
                    <div class="en_container">
                        <div class="point"></div>
                        LIVING HALL
                    </div>
                    <div class="cn_container">
                        新疆⽣活馆
                    </div>
                </div>
                <div class="img_container">
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/10.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            index: 0,
            maxIndex: 3,
            isOpen: false,
            itemIndex: 0,
            dataList: [
                {
                    url: 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/4.png',
                    cnText: '天马体验',
                    enText: 'Tianma experience'
                },
                {
                    url: 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/5.png',
                    cnText: '文化长廊',
                    enText: 'CULTURAL CORRIDOR'
                },
                {
                    url: 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/6.png',
                    cnText: '冰雪乐园',
                    enText: 'ICE AND SNOW PARK'
                },
                {
                    url: 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/7.jpg',
                    cnText: '可视化互动产销区',
                    enText: 'PRODUCTION-SALES AREA'
                },
            ],
            textList: [
                {
                    cnTitle: '天马体验',
                    enTitle: 'Tianma experience',
                    text: '昭苏是天马的故乡，已举办30届享誉国内外的“天马国际旅游节”，好物疆至将在旅产园内建立“天马体验”业态，分为两大体验内容：一是成人马术体验，让来昭苏的游客充分感受在草原上策马奔腾的豪迈，体验昭苏天马的神俊；二是宠物马体验，让家庭式游客中的儿童也可尽享骑乘的乐趣，同时成为旅产园对外宣传的一个重要爆点。',
                },
                {
                    cnTitle: '文化长廊',
                    enTitle: 'CULTURAL  CORRIDOR',
                    text: '昭苏拥有丰富的冰雪旅游资源，好物疆至响应国家、自治区、自治州等各级政府关于发展冰雪旅游的规划，建立“冰雪乐园”业态，包含雪圈冲浪、冰雕、雪地摩托、冰上碰碰车马拉雪橇等游玩项目，为昭苏的冬季旅游增加新的内容。'
                },
                {
                    cnTitle: '冰雪乐园',
                    enTitle: 'ICE AND SNOW PARK',
                    text: '昭苏拥有丰富的冰雪旅游资源，好物疆至响应国家、自治区、自治州等各级政府关于发展冰雪旅游的规划，建立“冰雪乐园”业态，包含雪圈冲浪、冰雕、雪地摩托、冰上碰碰车马拉雪橇等游玩项目，为昭苏的冬季旅游增加新的内容。'
                },
                {
                    cnTitle: '可视化互动产销区',
                    enTitle: 'PRODUCTION-SALES AREA',
                    text: '昭苏拥有丰富的冰雪旅游资源，好物疆至响应国家、自治区、自治州等各级政府关于发展冰雪旅游的规划，建立“冰雪乐园”业态，包含雪圈冲浪、冰雕、雪地摩托、冰上碰碰车马拉雪橇等游玩项目，为昭苏的冬季旅游增加新的内容。'
                },
            ]
        }
    },
    mounted() {
        this.animate.moveIn(".section4_en", ".section4_en", "right");
        this.animate.moveIn(".section4_cn", ".section4_cn", "right");
    },

    methods: {
        goBack() {
            if (this.index > 0) {
                this.index--
                console.log(this.index, document.querySelector('.section4_imgs').clientWidth);
                document.querySelector('.section4_imgs_pages').style.left = `-${document.querySelector('.section4_imgs').clientWidth * this.index}px`
            }
        },
        goNext() {
            if (this.index < this.maxIndex) {
                this.index++
                console.log(document.querySelector('.section4_imgs').clientWidth * this.index);
                document.querySelector('.section4_imgs_pages').style.left = `-${document.querySelector('.section4_imgs').clientWidth * this.index}px`
            }
        },
        handleChange(index) {
            if (this.isOpen) {
                if (index === this.itemIndex) {
                    this.isOpen = false
                } else {
                    this.isOpen = false
                    setTimeout(() => {
                        this.itemIndex = index
                        this.isOpen = true
                    }, 600);
                }
            } else {
                this.itemIndex = index
                this.isOpen = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#page2body{
    display: block;
    width: 100%;
    position: relative;
    background: white;
    font-size: 24px;

    .section4_title{
        margin-top: 112px;
        .section4_en{
            font-size: 36px;
            line-height: 40px;
            color:#8bc243;
            text-align: center;
        }
        .section4_cn{
            width: 140px;
            text-align: center;
            font-size: 32px;
            line-height: 76px;
            margin:0 auto;
            color:#8bc243;
            border-bottom: 2px solid #8bc243;
            margin-bottom: 72px;
        }
    }

    .scroll_box{
        height: 450px;
        display: flex;
        overflow-x: scroll;

        .scroll_item{
            width: 258px;
            height: 424px;
            position: relative;
            margin: 0 18px;
            img{
            width:258px;
            height: 424px;
        }

            .scroll_item_model{
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height: 100%;
                background-color: rgba(0,0,0,.3);
                display: flex;
                align-content: center;
                justify-content: center;

                .scroll_item_model_text{
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    justify-content: center;
                    color:white;
                    div{
                        text-align: center;
                    }

                    .scroll_item_model_text_cn{
                        font-size: 22px;
                    }

                    .scroll_item_model_text_en{
                        font-size: 20px;
                        color:rgba($color:#fff,  $alpha: 0.6)
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar {

        display: none; /* Chrome Safari */

    }
    .text_container{
        width: 100%;
        height: 0px;
        transition: all .6s;
        box-sizing: border-box;
        overflow: hidden;
        .text_title{
            margin: 30px 38px;
            display: flex;
            align-items: center;
            .point{
                width:12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 22px;
                background-color: rgba(139,194,67,1);
            }

            .text_title_cn{
                font-size: 28px;
                margin-right: 22px;
            }

            .text_title_en{
                font-size: 24px;
                color:#999
            }
        }
        .text{
            margin: 0 38px;
            box-sizing: border-box;
            font-size: 24px;
            color:#666;
        }
    }
    .active{
        height: 350px;
    }

    .section4_container{
        padding: 0 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .goBack,.goNext{
            width: 34px;
            height: 34px;
            img{
                width: 34px;
                height: 34px;
            }
        }
        .section4_imgs{
            width: 556px;
            height: 396px;
            overflow: hidden;
            position: relative;

            .section4_imgs_pages{
                position: absolute;
                top:0;
                left:0;
                display: flex;
                transition: all .6s;

                .section4_imgs_page{
                    width: 556px;
                    height: 396px;
                    display: flex;
                    justify-content: space-between;

                    img{
                        width: 260px;
                        height: 396px;
                    }
                }
            }
        }
    }

    .section5{
        margin: 0 32px;
        box-sizing: border-box;

        .container1,.container2{
            .container_title{
                margin-top: 120px;
                margin-bottom: 58px;
                .en_container{
                    display: flex;
                    align-items: center;
                    font-size: 28px;
                    margin-bottom: 10px;
                    line-height: 41px;
                    .point{
                        width:12px;
                        height: 12px;
                        border-radius: 50%;
                        background-color: #8bc243;
                        margin-right: 22px;
                    }
                }

                .cn_container{
                    font-size: 24px;
                    color:rgba(102,102,102,1);
                    margin-left: 34px;
                }
            }

            .img_container{
                width: 462px;
                height: 406px;
                background-color: rgba(245,245,245,1);
                position: relative;

                img{
                    width:500px;
                    height: 320px;
                    position: absolute;
                    top:-32px;
                    left:86px;
                }
            }
        }

        .container2{
            display: flex;
            flex-direction: column;
            align-items: end;
            .container_title{
                display: flex;
                flex-direction: column;
                align-items: end;
            }
            .img_container{
                width: 462px;
                height: 406px;
                background-color: rgba(245,245,245,1);
                position: relative;

                img{
                    width:500px;
                    height: 320px;
                    position: absolute;
                    top:-30px !important;
                    left:-128px !important;
                }
            }
        }
    }
}
</style>