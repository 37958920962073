<template>
    <div id="homePage2" class="page2-wrap">
        <div class="img2_container">
            <div class="img_1"><img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/3.png" alt=""></div>
            <div class="img_2"><img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/2.png" alt=""></div>
        </div>
        <div class="page2_left">
            <div class="page2_left_title">
                <div class="page2_left_title_en">ABOUT US</div>
                <div class="page2_left_title_cn">关于我们</div>
            </div>
            <div class="page2_left_text">
                <div class="page2_left_text_section1">——</div>
                <div class="page2_left_text_section2">
                    新疆好物疆至企业管理有限公司成立于2021年，注册资本2000万人民币。
                </div>
                <div class="page2_left_text_section3">好物疆至以“新疆好物传颂者”为企业定位，以“重塑本地好物价值体系，促进本地特色文化传递，领航本地产业结 构升级，带动本地人民共同富裕”为企业使命。</div>
            </div>
        </div>
        <div class="page2_right_text">
            <div class="page2_right_text_section1">——</div>
            <div class="page2_right_text_section2">
                以“丰富社会主义市场经济体制，共享社会主义经济发展红利”为企业愿景，通过旗下旅游开发、文化传媒、贸易销售、食品加工、酒店运营、文创研发等相关子公司。
            </div>
            <div class="page2_right_text_section3">响应国家优化发展实体经济的政策，以幸福美好生活为导向，充分利用大美新疆的品质在地资源和历史文化传承，为广大消费者提供畅游新疆美景、感受新疆风情、持续消费新疆好物的多元复合场景综合服务。</div>
        </div>
        <div class="page2_title">
            <div>品质</div>
            <div class="point"></div>
            <div>快乐</div>
            <div class="point"></div>
            <div>家</div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.animate1 = this.animate.moveIn("#homePage2 .page2_left_title_en", "#homePage2 .page2_left_title_en", "right");
        this.animate2 = this.animate.moveIn("#homePage2 .page2_title", "#homePage2 .page2_title", "bottom");
        this.animate3 = this.animate.moveIn("#homePage2 .page2_left_title_cn", "#homePage2 .page2_left_title_cn", "top");
        this.animate4 = this.animate.moveIn("#homePage2 .page2_left_text", "#homePage2 .page2_left_text", "top");
        this.animate5 = this.animate.moveIn("#homePage2 .page2_right_text", "#homePage2 .page2_right_text", "left");
    },
}
</script>

<style lang="scss" scoped>
.page2-wrap {
    width: 1920px;
    height: 1080px;
    position: relative;
    overflow: hidden;

    .img2_container{
        width: 1320px;
        height: 706px;
        margin: 221px 300px 153px;
        background-color: #f7f7f7;
        //padding-top: 161px;
        box-sizing: border-box;
        display: flex;
        .img_1{
            margin-top: 84px;
        }
        .img_2{
            margin-top: 176px;
        }
        img{
            width: 560px;
            height: 450px;
            margin-left: 65px;
        }
    }
    .page2_title{
        width: 300px;
        height: 50px;
        display: flex;
        align-items: center;
        position: absolute;
        top:286px;
        right:88px;
        font-size: 36px;
        color:#8BC243;

        .point{
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #8BC243;
            margin: 14px;
        }
    }

    .page2_left{
        width: 228px;
        height: 457px;
        position: absolute;
        top:407px;
        left:112px;
        .page2_left_title{
            width: 100%;
            height: 142px;
            .page2_left_title_en{
                font-size: 30px;
                font-weight: bolder;
                color:#80BA3B;
                margin-bottom: 15px;
            }
            .page2_left_title_cn{
                font-size: 26px;
                color:#80BA3B;
            }
        }

        .page2_left_text{
            height: 245px;
            width: 284px;
            background-color: #80BA3B;
            padding: 0 22px ;
            box-sizing: border-box;
            overflow: hidden;

            .page2_left_text_section1{
                font-size: 24px;
                line-height: 24px;
                margin-top: 40px;
                margin-top: 16px;
                color:white;
            }

            .page2_left_text_section2,.page2_left_text_section3{
                font-size: 12px;
                line-height: 24px;
                color:white;
            }
            .page2_left_text_section3{
              margin-top: 30px;
            }
        }
    }

    .page2_right_text{
        height: 320px;
        width: 281px;
        background-color: #80BA3B;
        padding: 0 22px ;
        box-sizing: border-box;
        overflow: hidden;
        position: absolute;
        top:549px;
        right:112px;
        .page2_right_text_section1{
            font-size: 24px;
            line-height: 24px;
            margin-top: 40px;
            margin-top: 16px;
            color:white;
        }
        .page2_right_text_section2,.page2_right_text_section3{
            font-size: 12px;
            line-height: 24px;
            color:white;
        }
        .page2_right_text_section3{
          margin-top: 30px;
        }
    }

    .content-wrap {
        position: absolute;
        left: 440px;
        top: 150px;
        display: flex;
        flex-direction: column;

        .header-wrap {
            display: flex;

            .logo {
                width: 254px;
                align-self: flex-start;
            }
            .title {
                margin-left: 44px;
                font-size: 58px;
                color: #00C9A9;
                font-weight: bold;
            }
        }
        .desc-text {
            width: 1330px;
            margin-top: 60px;
            font-size: 20px;
            color: #fff;
        }
        .desc-text2{
            margin-top: 20px;
            width: 1070px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color:#00C9A9;

            .childText1{
                width: 482px;
                height: 100%;
                font-size: 30px;
                .childText3{
                    font-weight: 600;
                }
            }
            .childText2{
                width: 484px;
                height: 100%;
            }
        }
    }
}
</style>