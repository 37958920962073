<template>
    <div id="homePage1" class="page1-wrap">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide"><img style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/0%E8%BD%AE%E6%92%ADbanner/1wolaizixinjiang.png" class="page-bg-img1" width="100%"></div>
                <div class="swiper-slide"><img style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/0%E8%BD%AE%E6%92%ADbanner/2lvchanyuan.png" class="page-bg-img1" width="100%" @click="handlego('/hotelDisplay')"></div>
                <div class="swiper-slide"><img style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/0%E8%BD%AE%E6%92%ADbanner/3guaguopu.png" class="page-bg-img1" width="100%" @click="handlego('/tourism')"></div>
            </div><!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
        </div>
        <div class="slogan-wrap">
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
export default {
    mounted() {
        this.animate.moveIn("#homePage1", "#homePage1 .slogan-text", "right");
        this.animate.moveIn("#homePage1", "#homePage1 .introduce-wrap", "top", {
            ease: "none",
            delay: 0.4,
        });
        var mySwiper = new Swiper('.swiper-container', {
            direction: 'horizontal', // 垂直切换选项
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: ".swiper-pagination",

            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: 3000
            // 如果需要滚动条
            // scrollbar: {
            //   el: '.swiper-scrollbar',
            // },
        })
    },

    methods: {
        handlego(url) {
            this.$router.push(url)
        }
    }
}
</script>

<style lang="scss" scoped>
.page1-wrap {
    position: relative;
    overflow: hidden;
    height: 1080px;

    .swiper-container{
        widows: 1920px;
        height: 1080px;
    }

    .page-bg-img1 {
        height: 1080px;
        -webkit-user-drag: none;
        position: absolute;
        top:0;
        left:0;
        // animation: 10s changeImg2 linear infinite;
    }
    .page-bg-img2 {
        height: 1080px;
        -webkit-user-drag: none;
        position: absolute;
        top:0;
        left:0;
        animation: 10s changeImg linear infinite;
    }

    .slogan-wrap {
        position: absolute;
        top: 833px;
        left: 678px;

        .slogan-text {
            font-size: 36px;
            color: white;
        }
    }
    .introduce-wrap {
        width: 100%;
        height: 158px;
        position: absolute;
        left: 0;
        bottom: 94px;
        background: #00C9A9;
        padding-left: 278px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        .introduce-text {
            font-size: 50px;
            font-weight: bold;
            color: #fff;

            &:nth-child(1) {
                font-size: 40px;
            }
        }
    }

    @keyframes changeImg {
        0%{
            left:100%;
        }
        50%{
            left:0
        }
        100%{
            left:100%
        }
    }

    ::v-deep .swiper-pagination-bullet{
        width: 20px !important;
        height: 20px !important;
    }

    ::v-deep .swiper-pagination-bullet-active{
        background-color:rgba($color: #000000, $alpha: .8)
    }
}
</style>