<template>
    <div class="phone_page1">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide"><img class="page-bg-img" style="background: var(--img-bg)" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/app/1home/1.jpg" width="100%"></div>
                <div class="swiper-slide"><img class="page-bg-img" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/app/1home/2.jpg"  width="100%" @click="handlego('/hotelDisplay')"></div>
                <div class="swiper-slide"><img class="page-bg-img" src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/app/1home/3.jpg" width="100%" @click="handlego('/tourism')"></div>
            </div><!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
        </div>
        
        <div class="slogan-wrap">
        </div>
        
        <div class="section2">
            <div class="section_container">
                <div class="section_title">ABOUT US</div>
                <div class="section_title2">关于我们</div>
                <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/app/1home/2.png" alt="">
                <div class="section_text">
                    <div class="section_line"></div>
                    新疆好物疆至企业管理有限公司成立于2021年，注册资本2000万人民币。
                    <p style="margin-top:10px">好物疆至以“新疆好物传颂者”为企业定位，以“重塑本地好物价值体系，促进本地特色文化传递，领航本地产业结 构升级，带动本地人民共同富裕”为企业使命。</p> 
                </div>
                <div class="section_more">MORE ></div>
            </div>
        </div>
        <div class="section3">
            <div class="section_container">
                <div class="section3_title">
                    <div>品质</div>
                    <div class="section_title_point"></div>
                    <div>快乐</div>
                    <div class="section_title_point"></div>
                    <div>家</div>
                </div>
                <div class="section3_title2">关于我们</div>
                <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/app/1home/3.png" alt="">
                <div class="section3_text">
                    <div class="section_line"></div>
                    以“丰富社会主义市场经济体制，共享社会主义经济发展红利”为企业愿景，通过旗下旅游开发、文化传媒、贸易销售、食品加工、酒店运营、文创研发等相关子公司。
                    <p style="margin-top:10px">响应国家优化发展实体经济的政策，以幸福美好生活为导向，充分利用大美新疆的品质在地资源和历史文化传承，为广大消费者提供畅游新疆美景、感受新疆风情、持续消费新疆好物的多元复合场景综合服务。</p>
                </div>
                <div class="section3_more">MORE ></div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
export default {
    mounted() {
        this.animate.moveIn(".section_title", ".section_title", "right");
        this.animate.moveIn(".section_title2", ".section_title2", "left", {
            delay: 0.4,
        });
        this.animate.moveIn(".section_text", ".section_text", "top");
        this.animate.moveIn(".section_more", ".section_more", "right");
        this.animate.moveIn(".section3_title", ".section3_title", "right");
        this.animate.moveIn(".section3_title2", ".section3_title2", "right");
        this.animate.moveIn(".section3_text", ".section3_text", "top");
        this.animate.moveIn(".section3_more", ".section3_more", "left");
        var mySwiper = new Swiper('.swiper-container', {
            direction: 'horizontal', // 垂直切换选项
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: ".swiper-pagination",

            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: 3000
            // 如果需要滚动条
            // scrollbar: {
            //   el: '.swiper-scrollbar',
            // },
        })
    },
    methods: {
        handlego(url) {
            this.$router.push(url)
        }
    }
}
</script>

<style lang="scss" scoped>
.phone_page1 {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto;

    .section2{
        margin-top: 112px;
        width: 610px;
        height: 1156px;
        background-color: #f6f6f6;
        position: relative;
        left:108px;

        .section_container{
            width: 636px;
            height: auto;
            position: absolute;
            right:50px;
            padding-top: 62px;

            .section_title{
                font-size: 40px;
                line-height: 56px;
                color:#8bc243;
                margin-bottom: 32px;
            }

            .section_title2{
                font-size: 32px;
                line-height: 44px;
                color:#8bc243;
                margin-bottom: 24px;
            }

            img{
                width: 636px;
                height: 474px;
                box-sizing: border-box;
            }
            .section_text{
                width: 636px;
                height: 366px;
                background-color: #75b134;
                box-sizing: border-box;
                position: relative;
                top:-10px;
                padding: 64px 40px 44px;
                font-size: 20px;
                line-height: 40px;
                color:white;
                .section_line{
                    width: 42px;
                    height: 2px;
                    background-color: white;
                    position: absolute;
                    top:38px;
                    left:40px;
                }
            }

            .section_more{
                margin-top: 20px;
                font-size: 24px;
                line-height: 34px;
                color:#999;
                margin-left: 36px;
            }
        }
    }
    .section3{
        margin-top: 112px;
        width: 610px;
        height: 1356px;
        background-color: #f6f6f6;
        position: relative;
        left:30px;

        .section_container{
            width: 636px;
            height: auto;
            position: absolute;
            left:46px;
            padding-top: 62px;
            text-align: right;

            .section3_title{
                font-size: 40px;
                line-height: 56px;
                color:#8bc243;
                margin-bottom: 32px;
                display: flex;
                align-items: center;
                justify-content: end;

                .section_title_point{
                    width: 16px;
                    height: 16px;
                    margin: 0 14px;
                    border-radius: 50%;
                    background-color: #8bc243;
                }
            }

            .section3_title2{
                font-size: 32px;
                line-height: 44px;
                color:#8bc243;
                margin-bottom: 24px;
            }

            img{
                width: 636px;
                height: 474px;
                box-sizing: border-box;
            }
            .section3_text{
                width: 636px;
                height: 550px;
                background-color: #75b134;
                box-sizing: border-box;
                position: relative;
                top:-15px;
                padding: 64px 40px 44px;
                font-size: 20px;
                line-height: 40px;
                color:white;
                text-align: left;

                .section_line{
                    width: 42px;
                    height: 2px;
                    background-color: white;
                    position: absolute;
                    top:38px;
                    left:40px;
                }
            }

            .section3_more{
                margin-top: 20px;
                font-size: 24px;
                line-height: 34px;
                color:#999;
                margin-right: 36px;
            }
        }
    }
}
</style>