<template>
    <div class="home-page">
        <Page1 />
        <Page2 />
        <Page5 />
        <Page6 />
        <Page3 />
        <Page4 />
        <go-other-page :maxIndex="Max" v-show="show"></go-other-page>
    </div>
</template>

<script>
import Page1 from "./components/Page1/index"
import Page2 from "./components/Page2/index"
import Page5 from "./components/Page5/index"
import Page6 from "./components/Page6/index"
import Page3 from "./components/Page3/index"
import Page4 from "./components/Page4/index"
export default {
    name: "Home",
    components: { Page1, Page2, Page5, Page6, Page3, Page4 },
    data() {
        return {
            // 轮播位置
            swiperIndex: 0,
            maxIndex: 4,
            moving: false,
            height: 0,
            startTime: 2000,
            endTime: 0,
            Max: [0, 1, 2, 3],
            show: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.home-page {
    width: 100%;
    overflow: hidden;
}
</style>