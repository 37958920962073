<template>
    <div id="page2body">
        <div class="section4">
            <div class="section4_title">
                <div class="section4_en">PRODUCT DISPLAY</div>
                <div class="section4_cn">产品展示</div>
            </div>
            <div class="section4_container">
                <div class="goBack" @click="goBack">
                    <img :src="index === 0 ? 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page3_goBack.png' : 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/back_on.png'">
                </div>
                <div class="section4_imgs">
                    <div class="section4_imgs_pages">
                        <div class="section4_imgs_page">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChatafbbc30edddede41a67bb578cc33479d.png">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/11.pic.png">
                        </div>
                        <div class="section4_imgs_page">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/12.png">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/14.png">
                        </div>
                        <div class="section4_imgs_page">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat8663c4259249725f253e3f45b8187ef2.png">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat6d6799cfc36bd61d852107ee660af13f.png">
                        </div>
                        <div class="section4_imgs_page">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat-1.png">
                            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat51c54805fc3e935653704d63d499aa55.png">
                        </div>
                    </div>
                </div>
                <div class="goNext" @click="goNext">
                    <img :src="index >= maxIndex ? 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page3_goNext.png' : 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/next_on.png'">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            index: 0,
            maxIndex: 3,
        }
    },
    mounted() {
        this.animate.moveIn(".section4_en", ".section4_en", "right");
        this.animate.moveIn(".section4_cn", ".section4_cn", "right");
    },

    methods: {
        goBack() {
            if (this.index > 0) {
                this.index--
                console.log(this.index, document.querySelector('.section4_imgs').clientWidth);
                document.querySelector('.section4_imgs_pages').style.left = `-${document.querySelector('.section4_imgs').clientWidth * this.index}px`
            }
        },
        goNext() {
            if (this.index < this.maxIndex) {
                this.index++
                console.log(document.querySelector('.section4_imgs').clientWidth * this.index);
                document.querySelector('.section4_imgs_pages').style.left = `-${document.querySelector('.section4_imgs').clientWidth * this.index}px`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#page2body{
    display: block;
    width: 100%;
    position: relative;
    background: white;

    .section4_title{
        margin-top: 112px;
        .section4_en{
            font-size: 36px;
            line-height: 40px;
            color:#8bc243;
            text-align: center;
        }
        .section4_cn{
            width: 140px;
            text-align: center;
            font-size: 32px;
            line-height: 76px;
            margin:0 auto;
            color:#8bc243;
            border-bottom: 2px solid #8bc243;
            margin-bottom: 72px;
        }
    }

    .section4_container{
        padding: 0 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .goBack,.goNext{
            width: 34px;
            height: 34px;
            img{
                width: 34px;
                height: 34px;
            }
        }
        .section4_imgs{
            width: 556px;
            height: 396px;
            overflow: hidden;
            position: relative;

            .section4_imgs_pages{
                position: absolute;
                top:0;
                left:0;
                display: flex;
                transition: all .6s;

                .section4_imgs_page{
                    width: 556px;
                    height: 396px;
                    display: flex;
                    justify-content: space-between;

                    img{
                        width: 260px;
                        height: 396px;
                    }
                }
            }
        }
    }
}
</style>