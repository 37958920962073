<template>
    <div id="page3body">
       
    </div>
</template>

<script>
export default {
    mounted() {
        this.animate.moveIn(".section5_box", ".section5_box", "top")
    },
}
</script>

<style lang="scss" scoped>
#page3body{
    width: 100%;
    position: relative;
    display: block;
    margin-top: 128px;

    .section5_container{
        display: flex;
        justify-content: space-between;
        margin: 0 30px;
    }

    .section5_box{
        width: 332px;
        height: 470px;
        box-sizing: border-box;
        padding: 54px 34px;
        background-color: #f6f6f6;
        position: relative;

        .section5_box_title_en{
            font-size: 32px;
            line-height: 42px;
            color:#666;
            margin-bottom: 12px;
        }
        .section5_box_title_cn{
            font-size: 28px;
            line-height: 40px;
            color:#8e8e8e;
            margin-bottom: 52px;
        }

        .section5_box_text{
            display: flex;
            align-items: center;
            font-size: 22px;
            color:#999;
            .section5_box_point{
                width: 20px;
                height: 20px;
                margin-right: 14px;
                border-radius: 50%;
                background-color:#787878;
            }
        }

        img{
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 108px;
            left:34px
        }
    }
}
</style>