<template>
    <div id="homePage6" class="page6-wrap">
        <div class="imgs_1">
            <div class="page6_imgs_1">
                <div class="round"></div>
                <div>LITERARY AND CREATIVE</div>
            </div>
            <div class="ti">特色文创馆</div>
            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/8.png">
        </div>
        <div class="imgs_2">
            <div class="page6_imgs_1">
                <div class="round"></div>
                <div>SNACK MELON AND FRUIT</div>
            </div>
            <div class="ti">零食瓜果铺</div>
            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/9.png">
        </div>
        <div class="imgs_3">
            <div class="page6_imgs_1">
                <div class="round"></div>
                <div>LIVING HALL</div>
            </div>
            <div class="ti">新疆⽣活馆</div>
            <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/10.png">
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.page6-wrap{
  margin-left: 300px;
  margin-top: 100px;
  width: 100%;
  height: 1000px;
  line-height: 50px;
  .imgs_1,.imgs_2,.imgs_3{
    width: 500px;
  .page6_imgs_1{
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 22px;
    opacity: .7;
    .round{
      left: 342px;
      top: 1083px;
      width: 12px;
      height: 12px;
      opacity: 1;
      border-radius:50px;
      background: rgba(139, 194, 67, 1);
      margin-bottom: 6px;
      margin-right: 10px;
    }
  }
  .ti{
    font-size: 20px;
    margin-left: 20px;
    opacity: .4;
  }
  img{
    height: 304px;
    width: 464px;
    margin-top: 30px;
  }
  }
  .imgs_2{
    margin-left: 720px;
    position: relative;
    top: -160px;
  }
  .imgs_3{
    position: relative;
    top: -300px;
  }
}
</style>