<template>
    <div id="homePage3" class="page3-wrap">
        <div class="page3_imgs">
            <div class="page3_imgs_container">
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/11.pic.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/12.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/14.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChatafbbc30edddede41a67bb578cc33479d.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat8663c4259249725f253e3f45b8187ef2.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat6d6799cfc36bd61d852107ee660af13f.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat-1.png" alt="">
                </div>
                <div>
                    <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/img/%E4%BA%A7%E5%93%81/WeChat51c54805fc3e935653704d63d499aa55.png" alt="">
                </div>
            </div>
        </div>
        <div class="page3_title">
            <div class="page3_title_en">
                PRODUCT DISPLAY
            </div>
            <div class="page3_title_cn">
                <div class="page_title_c">
                    产品展示
                </div>
            </div>
        </div>
        <div class="goNext" @click="goNext" @mouseover="Next = true" @mouseout="Next = false">
            <img :src=" Next ? 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/next_on.png' : 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page3_goNext.png'" />
        </div>
        <div class="goBack" @click="goBack" @mouseover="Back = true" @mouseout="Back = false">
            <img :src="Back ? 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/back_on.png' : 'https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page3_goBack.png'" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            index: 0,
            Next: false,
            Back: false,
        }
    },
    mounted() {
        this.animate1 = this.animate.moveIn("#homePage3 .page3_title_en", "#homePage3 .page3_title_en", "top")
        this.animate2 = this.animate.moveIn("#homePage3 .page_title_c", "#homePage3 .page_title_c", "top", {
            stagger: 0,
        })
        // this.animate3 = this.animate.moveIn("#homePage3 .advantage-item2", "#homePage3 .advantage-item2", "top", {
        //     stagger: 0
        // })
    },

    methods: {
        goNext() {
            if (this.index >= document.querySelector('.page3_imgs_container').offsetWidth - (document.querySelector('.page3_imgs_container>div>img').offsetWidth * 4) - 10) {
                console.log("没了，看不动了");
            } else {
                this.index += document.querySelector('.page3_imgs_container>div>img').offsetWidth
                document.querySelector('.page3_imgs_container').style.left = `-${this.index}px`
            }
        },
        goBack() {
            if (this.index <= 0) {
                console.log("没了");
            } else {
                this.index -= document.querySelector('.page3_imgs_container>div>img').offsetWidth
                document.querySelector('.page3_imgs_container').style.left = `-${this.index}px`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page3-wrap {
    width: 100%;
    height: 1080px;
    position: relative;

    .page3_imgs{
        width: 1388px;
        height: 567px;
        position: relative;
        top:360px;
        margin: 0 auto;
        overflow: hidden;

        .page3_imgs_container{
            width: auto;
            height: 567px;
            white-space: nowrap;
            position: absolute;
            top:0px;
            left:0px;
            transition: all .6s;
            div{
                width: 347px;
                height: 567px;
                overflow: hidden;
                display: inline-block;
            }

            img{
                width: 347px;
                height: 567px;
                transition: all .6s;
            }

            img:hover{
                transform: scale(1.2);
            }
        }
    }

    .page3_title{
        width: 488px;
        height: 122px;
        position: absolute;
        top:183px;
        left:736px;

        .page3_title_en{
            font-size: 46px;
            color:#8bc243;
            text-align: center;
        }

        .page3_title_cn{
            display: flex;
            justify-content: center;

            .page_title_c{
                display: inline-block;
                color:#8bc243;
                font-size: 30px;
                line-height: 58px;
                border-bottom: 2px solid #8bc243;
            }
        }
    }
    .goNext{
        width:51px;
        height: 51px;
        position: absolute;
        top:610px;
        right:189px;
        cursor:pointer;
        img{
            width: 51px;
            height: 51px;
        }
    }

    .goBack{
        width:51px;
        height: 51px;
        position: absolute;
        top:610px;
        left:189px;
        cursor:pointer;
        img{
            width: 51px;
            height: 51px;
        }
    }
}
</style>