<template>
    <div class="page4-wrap">
        <div class="section5_container">
            <div class="section5_box">
                <div class="section5_box_title_en">JOIN US</div>
                <div class="section5_box_title_cn">招聘</div>
                <div class="section5_box_text">
                    <div class="section5_box_point"></div>
                  尊重人、培养人、成就人
                </div>
                <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/tubiao6.png" alt="">
            </div>
            <div class="section5_box">
                <div class="section5_box_title_en">HOW TO BUY</div>
                <div class="section5_box_title_cn">购买方式</div>
                <div class="section5_box_text">
                    <div class="section5_box_point"></div>
                  全国门店
                </div>
                <img src="https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/tubiao7.png" alt="">
            </div>
        </div>
        <footer1></footer1>
    </div>
</template>

<script>
export default {
    mounted() {
        this.animate.restructure(".phone_page4_box1", "#desc-item1")
        this.animate.restructure(".phone_page4_box1", "#desc-item2", {
            delay: 0.8,
        })
        // this.animate.moveIn(".phone_page4_box1", ".desc-item", "top");
    },
}
</script>

<style lang="scss" scoped>
.page4-wrap {
    display: block;
    width: 100%;
    position: relative;
    margin-top: 26px;

    .section5_container{
        display: flex;
        justify-content: space-between;
        margin: 0 30px;
    }

    .section5_box{
        width: 332px;
        height: 470px;
        box-sizing: border-box;
        padding: 54px 34px;
        background-color: #f6f6f6;
        position: relative;

        .section5_box_title_en{
            font-size: 32px;
            line-height: 42px;
            color:#666;
            margin-bottom: 12px;
        }
        .section5_box_title_cn{
            font-size: 28px;
            line-height: 40px;
            color:#8e8e8e;
            margin-bottom: 52px;
        }

        .section5_box_text{
            display: flex;
            align-items: center;
            font-size: 22px;
            color:#999;
            .section5_box_point{
                width: 20px;
                height: 20px;
                margin-right: 14px;
                border-radius: 50%;
                background-color:#787878;
            }
        }

        img{
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 108px;
            left:34px
        }
    }
}
</style>