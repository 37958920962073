<template>
    <div class="page4-wrap">
        <div class="page4_container">
            <div class="page4_text" v-for=" ( v,i ) in options" :key="i" @mouseover="v.isHover=true" @mouseout="v.isHover=false" :style="v.isHover ? 'background-color:#8bc243' : ''">
                <div class="page4_text_title_en" :style="v.isHover ? 'color:white' : ''">{{ v.enTitle }}</div>
                <div class="page4_text_title_cn" :style="v.isHover ? 'color:white' : ''">{{ v.cnTitle }}</div>
                <div class="page4_text_important" :style="v.isHover ? 'color:white' : ''">
                    <div class="page4_point" :style="v.isHover ? 'background-color:white' : ''"></div>{{ v.important }}</div>
                <div class="page4_text_container" :style="v.isHover ? 'color:white' : ''">{{ v.text }}</div>
                <img :src="v.isHover ? v.onSrc : v.src" />
            </div>
        </div>
        <Footer1 />
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                {
                    enTitle: "FRANCHISE",
                    cnTitle: "加盟专区",
                    important: "玩出生产力",
                    text: "线上线下高度融合，每个渠道，都是好物疆至与消费者联通的触点，让我们找到满足消费者需求的同时，客户也随时、随地、随意的享受毫无疆至的产品和服务。",
                    src: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img1.png",
                    onSrc: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img1_on.png",
                    isHover: false,
                },
                {
                    enTitle: "VIP",
                    cnTitle: "会员专区",
                    important: "吃货联盟",
                    text: "成为会员后，好物疆至会依据您的累积消费情况给您定级。",
                    src: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img2.png",
                    onSrc: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img2_on.png",
                    isHover: false,
                },
                {
                    enTitle: "JOIN  US",
                    cnTitle: "招聘",
                    important: "尊重人、培养人、成就人",
                    text: "我们是新零售的“食尚先锋”是引领美味潮流的“创想家”是健康品质管理的“食力派”。美食由你定制，未来油泥定义。",
                    src: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img3.png",
                    onSrc: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img3_on.png",
                    isHover: false,
                },
                {
                    enTitle: "HOW TO BUY",
                    cnTitle: "购买方式",
                    important: "全国门店",
                    text: "可通过全国门店，使用好物疆至APP、小程序进行购买。",
                    src: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img4.png",
                    onSrc: "https://howu-1.oss-cn-beijing.aliyuncs.com/upload/howu_applet/website/web/1home/icon/page4_img4_on.png",
                    isHover: false,
                },
            ]
        }
    },
    mounted() {
        this.animate1 = this.animate.moveIn(".page4-wrap .page4_text", ".page4-wrap .page4_text", "top")
    },
}
</script>

<style lang="scss" scoped>
    .page4-wrap{
        width: 100%;
        height: 1080px;
        position: relative;
        .page4_container{
            width: 1396px;
            height: 533px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            position: relative;
            top:250px;
            left:260px;

            .page4_text{
                width: 334px;
                height: 544px;
                background-color: #f7f7f7;
                color:#999;
                padding: 0 30px;
                box-sizing: border-box;
                transition: all .6s;
                position: relative;

                .page4_text_title_en{
                    font-size: 36px;
                    color:black;
                    margin-top: 53px;
                    line-height: 47px;
                    font-weight: bolder;
                }

                .page4_text_title_cn{
                    font-size: 30px;
                    color:#999;
                    line-height: 42px;
                    margin-top: 9px;
                    margin-bottom: 38px;
                }

                .page4_text_important{
                    display: flex;
                    align-items: center;
                    height: 28px;
                    font-size: 20px;
                    margin-bottom: 55px;
                    .page4_point{
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        margin-right: 16px;
                        background-color: #8e8e8e;
                        border-radius: 50%;
                    }
                }

                .page4_text_container{
                    font-size: 14px;
                    line-height: 32px;
                    color:#999;
                }

                img{
                    position: absolute;
                    bottom: 48px;
                    left: 32px;
                    width: 47px;
                    height: 47px;
                }
            }
        }
    }
</style>